import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WorkspaceUserService from "../../services/WorkspaceUserService";

export const getAllWorkspaceUser = createAsyncThunk('get-all-workspace-user', async (data, { rejectWithValue }) => {
    try {
        const response = await WorkspaceUserService.getAllWorkspaceUser();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const deleteAllExceptWorkspaceUser = createAsyncThunk('delete-all-except-workspace-user', async (data, { rejectWithValue }) => {
    try {
        const response = await WorkspaceUserService.deleteAllExceptWorkspaceUser(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getAllWorkspaceUserSubscription = createAsyncThunk('get-all-workspace-user-subscription', async (data, { rejectWithValue }) => {
    try {
        const response = await WorkspaceUserService.getAllWorkspaceUserSubscription();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getWorkspaceUserBySubscriptionId = createAsyncThunk(
    'get-workspace-user-by-subscription-id',
    async ({ subscriptionId }, { rejectWithValue }) => {
        try {
            const response = await WorkspaceUserService.getWorkspaceUserBySubscriptionId({ subscriptionId: subscriptionId });
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const getAllWorkspaceUserSubscriptionIdWithout = createAsyncThunk(
    'get-workspace-user-by-subscription-id-without',
    async ({ subscriptionId }, { rejectWithValue }) => {
        try {
            const response = await WorkspaceUserService.getAllWorkspaceUserSubscriptionIdWithout({ subscriptionId: subscriptionId });
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const SetGoogleWorkspaceUserSynchronization = createAsyncThunk(
    'set-google-workspace-user-synchronization',
    async ({ workspaceUserId, confirm }, { rejectWithValue }) => {
        try {
            const response = await WorkspaceUserService.SetGoogleWorkspaceUserSynchronization(
                { workspaceUserId: workspaceUserId, confirm: confirm });
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const getGoogleWorkspaceUserSynchronized = createAsyncThunk(
    'get-google-workspace-user-synchronized',
    async (data, { rejectWithValue }) => {
        try {
            const response = await WorkspaceUserService.getGoogleWorkspaceUserSynchronized();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const addWorkspaceUser = createAsyncThunk('create-workspace-user', async (data, { rejectWithValue }) => {
    try {
        const response = await WorkspaceUserService.addWorkspaceUser(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const deleteWorkspaceUser = createAsyncThunk('delete-workspace-user', async (data, { rejectWithValue }) => {
    try {
        const response = await WorkspaceUserService.deleteWorkspaceUser(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const addServiceToWorkspaceUser = createAsyncThunk(
    'add-service-to-workspace-user',
    async ({ workspaceUserId, subscriptions }, { rejectWithValue }) => {
        try {
            const response = await WorkspaceUserService.addServiceToWorkspaceUser({workspaceUserId: workspaceUserId}, subscriptions);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const removeSubscriptionFromWorkspaceUser = createAsyncThunk(
    'remove-subscription-from-workspace-user',
    async ({subscriptionId, workspaceUserId }, { rejectWithValue }) => {
        try {
            const response = await WorkspaceUserService.removeSubscriptionFromWorkspaceUser({subscriptionId: subscriptionId, workspaceUserId: workspaceUserId});
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const updateWorkspaceUser = createAsyncThunk(
    'update-workspace-user',
    async ({workspaceUserId, data }, { rejectWithValue }) => {
        try {
            const response = await WorkspaceUserService.updateWorkspaceUser({workspaceUserId: workspaceUserId}, data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const getWorkspaceUserById = createAsyncThunk(
    'get-workspace-user-by-id',
    async ({workspaceUserId }, { rejectWithValue }) => {
        try {
            const response = await WorkspaceUserService.getWorkspaceUserById({workspaceUserId: workspaceUserId});
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);


const WorkspaceUserSlice = createSlice({
    name: 'workspaceUserSlice',
    initialState: {
        workspaceUser: [],
        workspaceUserSynchronized: [],
        workspaceUserSubscription: [],
        workspaceUserBySubscriptionId: [],
        workspaceUserBySubscriptionIdWithout: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(getAllWorkspaceUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllWorkspaceUser.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(getAllWorkspaceUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getWorkspaceUserById.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getWorkspaceUserById.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(getWorkspaceUserById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(updateWorkspaceUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(updateWorkspaceUser.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(updateWorkspaceUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(removeSubscriptionFromWorkspaceUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(removeSubscriptionFromWorkspaceUser.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(removeSubscriptionFromWorkspaceUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(addServiceToWorkspaceUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(addServiceToWorkspaceUser.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(addServiceToWorkspaceUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deleteWorkspaceUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(deleteWorkspaceUser.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(deleteWorkspaceUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(addWorkspaceUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(addWorkspaceUser.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(addWorkspaceUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getGoogleWorkspaceUserSynchronized.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getGoogleWorkspaceUserSynchronized.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUserSynchronized = action.payload;
            })
            .addCase(getGoogleWorkspaceUserSynchronized.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(SetGoogleWorkspaceUserSynchronization.pending, (state)=> {
                state.loading = true;
            })
            .addCase(SetGoogleWorkspaceUserSynchronization.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(SetGoogleWorkspaceUserSynchronization.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getWorkspaceUserBySubscriptionId.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getWorkspaceUserBySubscriptionId.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUserBySubscriptionId = action.payload;
            })
            .addCase(getWorkspaceUserBySubscriptionId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllWorkspaceUserSubscriptionIdWithout.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllWorkspaceUserSubscriptionIdWithout.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUserBySubscriptionIdWithout = action.payload;
            })
            .addCase(getAllWorkspaceUserSubscriptionIdWithout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllWorkspaceUserSubscription.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllWorkspaceUserSubscription.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUserSubscription = action.payload;
            })
            .addCase(getAllWorkspaceUserSubscription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deleteAllExceptWorkspaceUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(deleteAllExceptWorkspaceUser.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(deleteAllExceptWorkspaceUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },

});

export default WorkspaceUserSlice.reducer;