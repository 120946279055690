import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SubscriptionRecordService from "../../services/SubscriptionRecordService";

export const postSubscriptionRecord = createAsyncThunk('postSubscriptionRecord/bySubscriptionId', async (data, { rejectWithValue }) => {
    try {
        const response = await SubscriptionRecordService.postSubscriptionRecord(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getAllSubscriptionsRecords = createAsyncThunk('allSubscriptionRecords/getAll', async ({subscriptionId}, { rejectWithValue }) => {
    try {
        const response = await SubscriptionRecordService.getAllSubscriptionsRecords({subscriptionId: subscriptionId});
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getAllSubscriptionRecordStatistic = createAsyncThunk('allSubscriptionRecordStatistic/getAll-statistic', async ({subscriptionId}, { rejectWithValue }) => {
    try {
        const response = await SubscriptionRecordService.getAllSubscriptionRecordStatistic({subscriptionId: subscriptionId});
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getAllSubscriptionRecordTransaction = createAsyncThunk('subscriptionRecordTransaction/getAll-transaction-subRecord', async ({subscriptionId}, { rejectWithValue }) => {
    try {
        const response = await SubscriptionRecordService.getAllSubscriptionRecordTransaction({subscriptionId: subscriptionId});
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});



export const deleteSubscriptionRecordById = createAsyncThunk('subscriptionRecordById-remove/delete-sub', async (subscriptionRecordId, { rejectWithValue }) => {
    try {
        const response = await SubscriptionRecordService.deleteSubscriptionRecordById(subscriptionRecordId);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const editSubscriptionRecord = createAsyncThunk('edit-subscription-record/edit-pricing-plan', async (data, { rejectWithValue }) => {
    try {
        const response = await SubscriptionRecordService.editSubscriptionRecord(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const addWorkspaceUserToService = createAsyncThunk(
    'addWorkspaceUserToService/bySubscriptionId',
    async ({ subscriptionId, workspaceUserIds }, { rejectWithValue }) => {
        try {
            const response = await SubscriptionRecordService.addWorkspaceUserToService({ subscriptionId, workspaceUserIds });
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    });


const subscriptionRecordSlice = createSlice({
    name: 'subscriptionRecordSlice',
    initialState: {
        subscriptions: [],
        subscriptionsRecordStatistic: [],
        subscriptionsRecordTransaction: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(postSubscriptionRecord.pending, (state)=> {
                state.loading = true;
            })
            .addCase(postSubscriptionRecord.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })
            .addCase(postSubscriptionRecord.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(addWorkspaceUserToService.pending, (state)=> {
                state.loading = true;
            })
            .addCase(addWorkspaceUserToService.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })
            .addCase(addWorkspaceUserToService.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deleteSubscriptionRecordById.pending, (state)=> {
                state.loading = true;
            })
            .addCase(deleteSubscriptionRecordById.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })
            .addCase(deleteSubscriptionRecordById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            .addCase(editSubscriptionRecord.pending, (state)=> {
                state.loading = true;
            })
            .addCase(editSubscriptionRecord.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })
            .addCase(editSubscriptionRecord.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            .addCase(getAllSubscriptionsRecords.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllSubscriptionsRecords.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })
            .addCase(getAllSubscriptionsRecords.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllSubscriptionRecordStatistic.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllSubscriptionRecordStatistic.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptionsRecordStatistic = action.payload;
            })
            .addCase(getAllSubscriptionRecordStatistic.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllSubscriptionRecordTransaction.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllSubscriptionRecordTransaction.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptionsRecordTransaction = action.payload;
            })
            .addCase(getAllSubscriptionRecordTransaction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },

});

export default subscriptionRecordSlice.reducer;
