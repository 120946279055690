import fetch from "../auth/FetchInterceptor";

const WorkspaceUserService = {}

WorkspaceUserService.getAllWorkspaceUser = function () {
    return fetch({
        url: '/WorkspaceUser/get-all-workspace-user',
        method: 'get'
    })
}

WorkspaceUserService.deleteAllExceptWorkspaceUser = function (data) {
    return fetch({
        url: '/WorkspaceUser/delete-all-except-workspace-user',
        method: 'delete',
        data: data
    })
}

WorkspaceUserService.getAllWorkspaceUserSubscription = function () {
    return fetch({
        url: '/WorkspaceUser/get-all-workspace-user-subscriptions',
        method: 'get'
    })
}

WorkspaceUserService.getWorkspaceUserBySubscriptionId = function ({subscriptionId}) {
    return fetch({
        url: `/WorkspaceUser/get-workspace-user-by-subscription-id?subscriptionId=${subscriptionId}`,
        method: 'get'
    })
}

WorkspaceUserService.getAllWorkspaceUserSubscriptionIdWithout = function ({subscriptionId}) {
    return fetch({
        url: `/WorkspaceUser/get-workspace-user-by-subscriptionId?subscriptionId=${subscriptionId}`,
        method: 'get'
    })
}

WorkspaceUserService.getGoogleWorkspaceUserSynchronized = function () {
    return fetch({
        url: '/WorkspaceUser/get-google-workspaces-user-pending',
        method: 'get'
    })
}

WorkspaceUserService.SetGoogleWorkspaceUserSynchronization = function ({workspaceUserId, confirm}) {
    return fetch({
        url: `/WorkspaceUser/set-google-workspace-user-synchronization?workspaceUserId=${workspaceUserId}&confirm=${confirm}`,
        method: 'put'
    })
}

WorkspaceUserService.addWorkspaceUser = function (data) {
    return fetch({
        url: '/WorkspaceUser/create-workspace-user',
        method: 'post',
        data: data
    })
}

WorkspaceUserService.deleteWorkspaceUser = function (data) {
    return fetch({
        url: `/WorkspaceUser?workspaceUserId=${data}`,
        method: 'delete',

    })
}

WorkspaceUserService.addServiceToWorkspaceUser = function ({workspaceUserId}, data) {
    return fetch({
        url: `WorkspaceUser/add-subscription-to-workspaceUser?workspaceUserId=${workspaceUserId}`,
        method: 'post',
        data: data
    })
}

WorkspaceUserService.removeSubscriptionFromWorkspaceUser = function ({subscriptionId, workspaceUserId}) {
    return fetch({
        url: `/WorkspaceUser/remove-subscription-from-workspaceUser?workspaceUserId=${workspaceUserId}&subscriptionId=${subscriptionId}`,
        method: 'delete',
    })
}

WorkspaceUserService.updateWorkspaceUser = function ({workspaceUserId}, data) {
    return fetch({
        url: `/WorkspaceUser/update-workspace-user?workspaceUserId=${workspaceUserId}`,
        method: 'put',
        data: data
    })
}

WorkspaceUserService.getWorkspaceUserById = function ({workspaceUserId}) {
    return fetch({
        url: `/WorkspaceUser/get-workspace-user-by-id?workspaceUserId=${workspaceUserId}`,
        method: 'get',
    })
}

export default WorkspaceUserService;